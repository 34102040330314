import React from 'react'
import { Button } from 'reactstrap'
import notDataIcon from './noDataIcon.svg'
import Link from '~/components/Link'
import routes from '~/routes'

import styles from './NoDataOverlay.scss'
import { useStore, withStore } from '~/dataStore'

const NoDataOverlay = () => {
  const {
    app: {
      appDetails: { id: appId }
    }
  } = useStore()

  return (
    <div className={styles.noDataOverlay}>
      <div className={styles.noDataOverlayContainer}>
        <img src={notDataIcon} alt="" />
        <div className="ms-4">
          <h3 className="fw-bold lh-base">
            To start generating insights, create <br /> and send your first
            campaigns.
          </h3>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link route={appId ? routes.newCampaign : '/'} params={{ appId }}>
            <Button
              color=""
              className="border rounded-lg text-black shadow-md border-mine-shaft px-4 no-wrap text-base mt-3 bg-white">
              Start Campaign
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withStore(NoDataOverlay)
