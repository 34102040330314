import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import AreaCharts from '~/pages/OpportunitiesDashboard/EngagementSummary/AreaCharts'
import useGetEngagementSummary from '~/pages/OpportunitiesDashboard/useOpportunitiesDashboard'
import RadialCharts from '~/pages/OpportunitiesDashboard/EngagementSummary/RadialCharts'
import EngagementSummaryCarousel from './Carousel'
import Toolbar from './Toolbar'
import Integrations from '../Integrations'
import NoDataOverlay from '../NoDataOverlay'

const EngagementSummary = () => {
  const [range, setRange] = useState<number>()
  const [channel, setChannel] = useState('All Channels')
  const [showIntegration, setShowIntegration] = useState(false)
  const {
    app: {
      appDetails: { oeRangeInDays, id: appId }
    }
  } = useStore()
  const { data } = useGetEngagementSummary(appId, {
    range
  })

  useEffect(() => {
    if (oeRangeInDays) {
      setRange(oeRangeInDays[0])
    }
  }, [oeRangeInDays])

  return (
    <div>
      <h2>Engagement Summary</h2>
      <div className="mt-4">
        <div>
          <Toolbar
            channel={channel}
            toggleIntegrations={() => setShowIntegration((prev) => !prev)}
            setChannel={setChannel}
            rangeDays={oeRangeInDays}
            setRange={setRange}
            range={range}
          />
        </div>
        {showIntegration && (
          <div className="mt-4 pb-6">
            <Integrations />
          </div>
        )}
        <div className="mt-4 position-relative">
          {data && !data?.dataExists && <NoDataOverlay />}

          {/*  1280 Resolution */}
          <Row className="d-xxl-none">
            <Col xs={12} className="mb-4">
              <AreaCharts summaryData={data} currentRange={range} />
            </Col>
            <Col xs={12} className="mb-4">
              <RadialCharts summaryData={data} currentRange={range} />
            </Col>
            <Col xs={12}>
              <EngagementSummaryCarousel />
            </Col>
          </Row>

          {/*  1352 Resolution */}
          <Row className="d-none d-xxl-flex d-xxxl-none">
            <Col xs={12} xxl={9} className="mb-4">
              <AreaCharts summaryData={data} currentRange={range} />
            </Col>
            <Col xs={12} xxl={3} className="mb-4">
              <RadialCharts
                direction="vertical"
                summaryData={data}
                currentRange={range}
              />
            </Col>
            <Col xs={12}>
              <EngagementSummaryCarousel />
            </Col>
          </Row>

          {/*  1600 Resolution */}
          <Row className="d-none d-xxxl-flex">
            <Col xxxl={6} widths={['xxxl']} className="mb-4">
              <AreaCharts summaryData={data} currentRange={range} />
            </Col>
            <Col xxxl={6} widths={['xxxl']} className="mb-4">
              <div className="justify-content-between h-100 flex-column d-flex">
                <EngagementSummaryCarousel />
                <RadialCharts summaryData={data} currentRange={range} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default withStore(EngagementSummary)
