import cn from 'classnames'
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { IPerformanceTopCampaign } from '~/pages/OpportunitiesDashboard/OpportunitiesDashboard.interface'
import {
  useExportPerformanceOops,
  useGetTopCampaigns
} from '~/pages/OpportunitiesDashboard/useOpportunitiesDashboard'
import { useStore, withStore } from '~/dataStore'

import styles from './TopCampaigns.scss'
import NoDataOverlay from '~/pages/OpportunitiesDashboard/NoDataOverlay'

const TopCampaigns = () => {
  const {
    app: {
      appDetails: { downloadOpportunityFiles, id }
    }
  } = useStore()
  const { data } = useGetTopCampaigns(id)
  const { mutate: exportPerformanceOops } = useExportPerformanceOops()

  return (
    <>
      <p className="text-muted text-20">
        These are your top campaigns ranked by new opportunities created
      </p>
      <Card className={cn(styles.topCampaignsTableWrapper)}>
        {data && data?.length === 0 && <NoDataOverlay />}
        <Table align="center">
          <tbody>
            {data?.map((row: IPerformanceTopCampaign, index: number) => (
              <tr key={row.id}>
                <th scope="row" className="align-middle">
                  <span
                    className={cn(
                      'py-3 px-2 border border-3 rounded-pill',
                      {
                        'border-yellow-light text-yellow-light': index + 1 === 1
                      },
                      { 'border-gray-light text-gray-light': index + 1 === 2 },
                      { 'border-warning text-warning': index + 1 === 3 },
                      { 'border-fire text-fire': index + 1 === 4 },
                      { 'border-gray text-gray': index + 1 === 5 }
                    )}>
                    {index + 1}
                  </span>
                </th>
                <td className="fw-bold">{row.name}</td>
                <td className="align-middle">
                  <p className="mb-0 fw-bold">
                    {row.opportunities?.toLocaleString('en-US')}
                  </p>
                  <p className="mb-0">
                    <span className="d-xxl-none d-xxxl-block">
                      Opportunities
                    </span>
                    <span className="d-none d-xxl-block d-xxxl-none">Opps</span>
                  </p>
                </td>
                <td className="text-end">
                  <Button
                    id={`opp-file-${row.id}`}
                    disabled={!downloadOpportunityFiles?.performanceFiles}
                    onClick={() =>
                      exportPerformanceOops({ exportPath: row.exportPath })
                    }
                    color=""
                    className="border rounded-lg text-black shadow-md border-mine-shaft px-4 no-wrap text-base">
                    <span className="d-xxl-none d-xxxl-block">
                      Opportunities File
                    </span>
                    <span className="d-none d-xxl-block d-xxxl-none">
                      Opp File
                    </span>
                  </Button>
                  {!downloadOpportunityFiles?.performanceFiles && (
                    <UncontrolledTooltip
                      popperClassName="tooltip-modern"
                      placement="top"
                      target={`opp-file-${row.id}`}>
                      Opportunities File is being generated.
                    </UncontrolledTooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  )
}

export default withStore(TopCampaigns)
