import { ApexOptions } from 'apexcharts'
import { isNumber } from 'chart.js/helpers'

export const RADIAL_CHART_OPTIONS: ApexOptions = {
  labels: [],
  chart: {
    type: 'radialBar',
    toolbar: {
      show: false
    }
  },
  states: {
    hover: {
      filter: {
        type: '',
        value: 1
      }
    }
  },
  plotOptions: {
    radialBar: {
      startAngle: 0,
      hollow: {
        margin: 0,
        size: '70%',
        background: '#fff',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
        dropShadow: {
          enabled: false,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: '#EEF2F7',
        strokeWidth: '100%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.35
        }
      },

      dataLabels: {
        show: true,
        name: {
          show: false
        },
        value: {
          color: '#333333',
          fontSize: '36px',
          fontFamily: 'Poppins',
          fontWeight: 800,
          show: true,
          formatter(val: number): string {
            // Show "--" without percentage if value is still being generated
            if (!isNumber(val)) {
              return val
            }
            return `${parseFloat(val.toFixed(1))}%`
          }
        }
      }
    }
  },
  fill: {
    colors: ['#C050BA']
  },
  stroke: {
    lineCap: 'round'
  }
}

export interface IEngagementSummary {
  dataExists: boolean
  activeUsers: {
    total: {
      count: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
    new: {
      count: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
  }
  engagement: {
    push: {
      rate: number
      diff: number
      actual: number
      total: number
    }
    geo: {
      rate: number
      diff: number
      actual: number
      total: number
    }
  }
  performance: {
    clicks: {
      count: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
    ctr: {
      rate: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
    engagementScore: {
      rate: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
    impressions: {
      count: number
      diff: number
      graph: {
        x: string
        y: number
      }[]
    }
  }
}

export type IPerformanceTopCampaign = {
  id: number
  name: string
  opportunities: number
  exportPath: string
}

export type IPerformanceTopNotifications = {
  key: string
  value: number
}
